import React from 'react';

import {
    DashboardOutlined,
    ShoppingOutlined,
    ImportOutlined,
    FileTextOutlined,
    FolderOutlined
} from '@ant-design/icons';

const menu = [
    {
        path: '/',
        name: 'Dashboard',
        icon: <DashboardOutlined className="sidebar-icon" />,
        permission: null
    },
    {
        path: '/products',
        name: 'Product list',
        icon: <ShoppingOutlined className="sidebar-icon" />,
        permission: 'products'
    },
    {
        path: '/products-import',
        name: 'Products import',
        icon: <ImportOutlined className="sidebar-icon" />,
        permission: 'products_import'
    },
    {
        path: '/products-description',
        name: 'Products description',
        icon: <FileTextOutlined className="sidebar-icon" />,
        permission: 'products_description'
    },
    {
        path: '/product-type',
        name: 'Product type',
        icon: <FolderOutlined className="sidebar-icon" />,
        permission: 'product_type'
    }
];

export default menu;